import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Trolling`}</h1>
    <ul>
      <li parentName="ul">{`The internet being the internet, there are people on it. People do nefarious things. Even though `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` is chaotic because anyone can submit any statement at any time, `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` is pretty much a straightjacket, insofar as online conversations go, because replies are structured as `}<a parentName="li" {...{
          "href": "/agree",
          "title": "agree"
        }}>{`agree`}</a>{`, `}<a parentName="li" {...{
          "href": "/disagree",
          "title": "disagree"
        }}>{`disagree`}</a>{` and `}<a parentName="li" {...{
          "href": "/pass",
          "title": "pass"
        }}>{`pass`}</a>{`.`}</li>
      <li parentName="ul">{`Because `}<a parentName="li" {...{
          "href": "/Polis",
          "title": "Polis"
        }}>{`Polis`}</a>{` is primarily based on structured `}<a parentName="li" {...{
          "href": "/votes",
          "title": "votes"
        }}>{`votes`}</a>{` rather than purely on `}<a parentName="li" {...{
          "href": "/comments",
          "title": "comments"
        }}>{`comments`}</a>{`, there is a good amount of metadata provided on extreme opinions. Thus, we encourage extreme opinions as data that can be used to understand populations.`}</li>
      <li parentName="ul">{`If you are concerned about `}<a parentName="li" {...{
          "href": "/spam",
          "title": "spam"
        }}>{`spam`}</a>{` in your conversation, enable `}<a parentName="li" {...{
          "href": "/strict-moderation",
          "title": "strict moderation"
        }}>{`strict moderation`}</a>{`.`}</li>
      <li parentName="ul">{`If you are worried about individuals or organizations `}<a parentName="li" {...{
          "href": "/gaming-the-system",
          "title": "gaming the system"
        }}>{`gaming the system`}</a>{`, read that page.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      